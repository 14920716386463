import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Header } from './components/ui';
import { About, Home, NotFound } from './pages';

import './App.scss';
import { useRef } from 'react';

function Layout() {
  return (
    <>
      <Header />
      <div className="content">
        <Outlet />
      </div>
    </>
  );
}

function App() {
  let location = useLocation();

  return (
    <div className="App">
      <Header />
      <TransitionGroup className="content">
        <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
          <Routes location={location}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
