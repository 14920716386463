import { Page, VideoFrame } from 'components/ui';
import { HomeCanvas } from 'components/canvas';

import './Home.scss';

export default function Home() {
  return (
    <Page className="Home">
      <HomeCanvas />
      <div className="Home__content">
        <div className="Home__content__left">
          <h1 className="Home__title">
            Hey! We're <span className="roulz-text">ROULZ</span>
          </h1>
          <h2 className="Home__subtitle">We love web development and share our knowledge with you!</h2>

          <p>
            Currently, we mainly work on&nbsp;
            <a href="https://licensephobia.com" target="_blank" rel="noopener noreferrer">
              Licensephobia
            </a>
            , so you don't need to be afraid of licenses anymore!
            <br />
            Until then, feel free to visit our&nbsp;
            <a href="https://github.com/roulzhq" target="_blank" rel="noopener noreferrer">
              Github
            </a>
            &nbsp;and have a look at what we are doing! Maybe even give us some feedback or contribute?
          </p>

          <div className="Home__buttons">
            <p>Follow us on</p>
            <a href="https://github.com/roulzhq" target="_blank" rel="noopener noreferrer" className="button">
              Github
            </a>
            <a href="https://twitter.com/roulzhq" target="_blank" rel="noopener noreferrer" className="button">
              Twitter
            </a>
            <a href="https://instagram.com/roulzhq" target="_blank" rel="noopener noreferrer" className="button">
              Instagram
            </a>
          </div>
        </div>
        <div className="Home__content__right">
          <h3>Check out our latest video!</h3>
          <VideoFrame className="Home__video" link="https://www.youtube.com/watch?v=MhYNyfgrCjg" target="_blank">
            <img
              src="content/youtube/thumbnails/thumbnail_typescript_1.png"
              alt="All you need to know about typescript"
            />
          </VideoFrame>
        </div>
      </div>
    </Page>
  );
}
