import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { CameraShake, Stars } from '@react-three/drei';

import './HomeCanvas.scss';

export default function HomeCanvas(): JSX.Element {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Canvas className="home-canvas">
        <CameraShake />
        <Stars radius={100} depth={120} count={4000} factor={4} saturation={0} />
      </Canvas>
    </Suspense>
  );
}
