import { Link } from 'react-router-dom';

import './Header.scss';

const menuItems: { label: string; url: string }[] = [
  { label: 'Home', url: '/' },
  { label: 'About', url: '/about' },
];

export default function Header() {
  const menu = menuItems.map((item) => (
    <Link to={item.url} key={item.url} className="header__menu__item">
      {item.label}
    </Link>
  ));

  return (
    <header className="header">
      <Link to="/" className="header__logo">
        <img src="../assets/images/logo-full.svg" alt="ROULZ Media Technologies" />
      </Link>
      <div className="header__menu">{menu}</div>
    </header>
  );
}
