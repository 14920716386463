import React from 'react';
import classNames from 'classnames';

import './Page.scss';

export interface PageProps {
  children: React.ReactNode;
  className: string;
}

export default function Page({ children, className }: PageProps) {
  const classes = classNames(className, 'page');

  return <div className={classes}>{children}</div>;
}
